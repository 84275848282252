<template>
    <div class="product-card prd-card">
        <div class="product-card-top">
            <a :href="productUrl" class="product-image">
                <img :src="baseImage" :class="{ 'image-placeholder': !hasBaseImage }" :alt="product.name" />
            </a>

            <div class="product-card-actions">
                <button class="btn btn-wishlist" :class="{ added: inWishlist }"
                    :title="$trans('storefront::product_card.wishlist')" @click="syncWishlist">
                    <i class="la-heart" :class="inWishlist ? 'las' : 'lar'"></i>
                </button>

                <button class="btn btn-compare" :class="{ added: inCompareList }"
                    :title="$trans('storefront::product_card.compare')" @click="syncCompareList">
                    <i class="las la-random"></i>
                </button>
            </div>

            <ul class="list-inline product-badge">
                <li class="badge badge-danger" v-if="item.is_out_of_stock">
                    {{ $trans("storefront::product_card.out_of_stock") }}
                </li>

                <li class="badge badge-primary" v-else-if="product.is_new">
                    {{ $trans("storefront::product_card.new") }}
                </li>

                <li class="badge badge-danger has_percentage_special_price" v-if="item.has_special_price">
                    <span v-if="item.has_percentage_special_price">
                        -{{ item.special_price_percent }}%
                    </span>
                    <span v-else>
                        PROMO
                    </span>
                </li>
            </ul>
        </div>

        <div class="product-card-middle">
            <product-rating :ratingPercent="product.rating_percent" :reviewCount="product.reviews.length">
            </product-rating>

            <a :href="productUrl" class="product-name">
                <h6>{{ product.name }} | #{{ product.id }}</h6>
            </a>

            <div class="product-price product-price-clone" v-html="item.formatted_price"></div>
        </div>

    

        <div class="product-card-bottom">
            <table class="table">

                <tbody>
                    <tr>
                        <td>{{ $trans("storefront::product_card.packing") }}</td>
                        <td v-html="(item.unit_value + item.unit) || '-'"></td>
                    </tr>

                    <tr>
                        <td>{{ $trans("storefront::product_card.price_per_unit") }}</td>
                        <td v-html="pricePerUnit"></td>
                    </tr>
                    <tr>
                        <td>{{ $trans("storefront::product_card.expried") }}</td>
                        <td class="text-danger" v-html="item.expried_date || '-'"></td>
                    </tr>
                    <tr v-if="allowToReport">
                        <td colspan="2">
                            <div class="input-group input-group-md">
                                <input type="text" v-model="item.area" v-mask="'A-###-#-A'" :masked="true"
                                    class="form-control" title="Pattern should be in the format A-000-1-A"
                                    @focus="selectAll" />
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" @click="updateProductArea">
                                        <i class="las la-save"></i>
                                    </button>
                                </div>
                            </div>
                            <span class="text-muted mt-1 d-block">
                                (AREA e.g., A-123-4-B)
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="product-price" v-html="item.formatted_price"></div> -->
            <div class="d-flex align-items-center">
                <template v-if="cartItem?.id">
                    <!-- Quantity Select -->
                    <select v-model="cartItem.qty" class="form-control input-number input-quantity mr-2"
                        @change="changeQuantity(Number(cartItem.qty))">
                        <option v-for="n in maxQuantity(cartItem)" :key="n" :value="n">
                            {{ n }}
                        </option>
                    </select>

                    <!-- Update Quantity Button -->
                    <button type="button" class="btn btn-sm btn-primary mr-2"
                        :disabled="isQtyIncreaseDisabled(cartItem)" @click="updateQuantity(cartItem.qty + 1)"
                        style="width: 80px;">
                        <span v-if="addingToCart" class="btn-loading">&nbsp;</span>
                        <span v-else>
                            <i class="las la-cart-arrow-down"></i>
                        </span>
                    </button>

                    <!-- Delete Button -->
                    <button type="button" class="btn btn-sm btn-danger" @click="updateQuantity(0)"
                        style="width: 40px; padding: 0.25rem 0.5rem; font-size: 0.8rem; color:white">
                        <span>
                            <i class="las la-trash white"></i>
                        </span>
                    </button>
                </template>
                <template v-else>
                    <button v-if="hasNoOption || item.is_out_of_stock" class="btn btn-sm btn-block"
                        :class="{ 'btn-primary': !item.is_out_of_stock, 'btn-danger': item.is_out_of_stock }"
                        :disabled="item.is_out_of_stock" @click="add">
                        <i class="las la-cart-arrow-down"></i>
                        <span v-if="addingToCart" class="btn-loading"></span>
                        <span v-else>
                            <span v-if="item.is_out_of_stock">
                                {{ $trans("storefront::product_card.out_of_stock") }}
                            </span>
                            <span v-else>

                                {{ $trans("storefront::product_card.add_to_cart") }}
                            </span>
                        </span>
                    </button>

                    <a v-else :href="productUrl" class="btn btn-primary btn-add-to-cart">
                        <i class="las la-eye"></i>
                        {{ $trans("storefront::product_card.view_options") }}
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import ProductCardMixin from "../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],

    props: ["product"],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },

    },
    data() {
        return {
            cartItem: null,
            productStatues: window.AyanCart.productStatues || {},
            allowToReport: window.AyanCart.allowToReport
        };
    },
    watch: {
        "cartItem.qty": {
            handler: function (newCart, oldCart) {
                if (isNaN(newCart) && this.cartItem) {
                    this.cartItem.qty = oldCart || 1;
                    return
                }
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            deep: true,
        },
        "store.state.cart.items": {
            handler: function (newItems, oldItems) {
                consoe.log(newItems, oldItems, 315)
                const productId = this.product.id;
                this.cartItem = store.getProductFromCart(productId);
            },
            deep: true,
        },

        "product.status": function (newVal, oldVal) {
            if (newVal) {
                this.updateProductStatus(newVal)
            }
        },

    },
    mounted() {
        this.cartItem = store.getProductFromCart(this.product.id); // Initialize cartItem when component is mounted
    },
    methods: {
        selectAll(event) {
            event.target.select();
        },
    },
};
</script>
