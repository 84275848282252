import { watch } from "vue";
import Errors from "../../../Errors";
import { trans } from "../../../functions";

export default {
    props: ["initialVisites", "countries", "customersPaginated"],
    data() {
        return {
            visites: this.initialVisites,
            form: {
                activity_type: "visit",
                activity_date: new Date().toISOString().substr(0, 10),
                status: "Completed",
                latitude: "",
                longitude: "",
                client_name: "",
                is_new_customer: false,
                assigned_date: "",
                completed_date: "",
                notes: "",
                country: "",
                state: "",
                city: "",
                zip: ""
            },
            states: {},
            errors: new Errors(),
            formOpen: false,
            editing: false,
            loading: false,
            locationPermissionGranted: false,
        };
    },

    created() {
        this.requestLocationPermission();
    },

    methods: {
        edit(activity) {
            if (!this.locationPermissionGranted) {
                this.$notify(trans('user::messages.location_permission_required'));
                return;
            }
            this.formOpen = true;
            this.editing = true;

            this.form = {
                id: activity.id,
                activity_type: activity.activity_type,
                activity_date: activity.activity_date,
                status: activity.status,
                latitude: activity.latitude,
                longitude: activity.longitude,
                client_name: activity.client_name,
                is_new_customer: activity.is_new_customer,
                assigned_date: activity.assigned_date,
                completed_date: activity.completed_date,
                notes: activity.notes,
                country: activity.country,
                state: activity.state,
                city: activity.city,
                zip: activity.zip,
            };
        },

        cancel() {
            this.editing = false;
            this.formOpen = false;
            this.errors.reset();
            this.resetForm();
        },

        save() {
            if (!this.locationPermissionGranted) {
                this.$notify(trans('user::messages.location_permission_required'));
                return;
            }
            this.loading = true;

            if (this.editing) {
                this.update();
            } else {
                this.create();
            }
        },

        update() {
            axios
                .put(
                    route("account.visites.update", { id: this.form.id }),
                    this.form
                )
                .then(({ data }) => {
                    this.formOpen = false;
                    this.editing = false;

                    const index = this.visites.findIndex(
                        (activity) => activity.id === data.activity.id
                    );
                    if (index !== -1) {
                        this.$set(this.visites, index, data.activity);
                    }

                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }
                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        create() {
            axios
                .post(route("account.visites.store"), this.form)
                .then(({ data }) => {

                    this.formOpen = false;

                    this.visites.push(data.activity);


                    this.resetForm();
                    this.$notify(data.message);
                    // scroll to the top 
                    window.scrollTo(0, 0);

                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }
                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resetForm() {
            this.form = {
                activity_type: "",
                activity_date: "",
                status: "",
                latitude: "",
                longitude: "",
                client_name: "",
                is_new_customer: false,
                assigned_date: "",
                completed_date: "",
                notes: "",
                country: "",
                state: "",
                city: "",
                zip: ""
            };
        },

        requestLocationPermission() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    this.showPosition.bind(this),
                    this.showError.bind(this)
                );
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        },

        showPosition(position) {
            this.locationPermissionGranted = true;
            
            this.form.latitude = position.coords.latitude;
            this.form.longitude = position.coords.longitude;
            console.log("Latitude: " + position.coords.latitude +
                " Longitude: " + position.coords.longitude);
        },

        showError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.locationPermissionGranted = false;
                    this.$notify(trans('user::messages.location_permission_denied'));
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.$notify(trans('user::messages.location_unavailable'));
                    break;
                case error.TIMEOUT:
                    this.$notify(trans('user::messages.location_request_timeout'));
                    break;
                case error.UNKNOWN_ERROR:
                    this.$notify(trans('user::messages.location_unknown_error'));
                    break;
            }
        },
    }
};
